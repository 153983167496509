import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User, UserAuth, UserIdentificationTypes, SimpleResponse } from '../../models/user.interface';
import { Address } from '../../models/address.interface';
import { Request } from '../../models/request.interface';
import { HttpGeneralService } from '../http-general/http-general.service';
import { Order } from 'src/app/shared/shared/models/order.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileHttpService extends HttpGeneralService {

  constructor(private http: HttpClient) {
    super();
  }

  getProfile(): Observable<UserAuth> {
    const url = `${environment.server}/user/min/info`;
    return this.http.get<UserAuth>(url);
  }

  getUserIdentifications(): Observable<Array<UserIdentificationTypes>> {
    const url = `${environment.server}/user/identification/type`;
    return this.http.get<{ types: Array<UserIdentificationTypes> }>(url).pipe(map(({ types }) => types));
  }

  putUser(user: User): Observable<any> {
    const url = `${environment.server}/user/profile`;
    return this.http.put(url, user);
  }

  putPassword(payload: string): Observable<any> {
    const url = `${environment.server}/user/password/new`;
    return this.http.put(url, { password: payload });
  }

  getShoppingHistory(req?: Request): Observable<Array<Order>> {
    const request = this.getRequest(req);
    const url = `${environment.server}/user/shopping/historic`;
    return this.http
      .get<{results: Array<Order>}>(url, { params: request.params, headers: request.headers })
      .pipe(map(({ results }) => results));
  }

  public sendPassRecoverLink(req?: Request): Observable<SimpleResponse> {
    const url = `${environment.server}/pass/recover/link`;
    const request = this.getRequest(req);

    return this.http.post<SimpleResponse>(url, null, { params: request.params, headers: request.headers });
  }

  public restorePass(req?: Request): Observable<SimpleResponse> {
    const url = `${environment.server}/pass/restore`;
    const request = this.getRequest(req);

    return this.http.put<SimpleResponse>(url, null, { params: request.params, headers: request.headers });
  }

  public activateAccount(req?: Request): Observable<SimpleResponse> {
    const url = `${environment.server}/signup/activate`;
    const request = this.getRequest(req);

    return this.http.post<SimpleResponse>(url, null, { params: request.params, headers: request.headers });
  }
}
