import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { HttpGeneralService } from 'src/app/core/services/http-general/http-general.service';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Request } from 'src/app/core/models/request.interface';
import { CartValidatePayload, CartValidateResponse, ProductCartPayload, ProductCartResponse } from '../../../models/cart.model';

@Injectable({
  providedIn: 'root'
})
export class CartHttpService extends HttpGeneralService {

  private http = inject(HttpClient);

  constructor() {
    super();
  }

  public getCart(req?: Request): Observable<Array<ProductCartResponse>> {
    const url = `${environment.server}/user/cart`;
    const request = this.getRequest(req);
    return this.http
      .get<{ shopping_cart: Array<ProductCartResponse>}>(url, { params: request.params, headers: request.headers })
      .pipe(
        map((response) => response.shopping_cart)
      );
  }

  public postCart(products: Array<ProductCartPayload>, req?: Request): Observable<any> {
    const url = `${environment.server}/user/cart`;
    const request = this.getRequest(req);
    return this.http
      .post<any>(url, {shopping_cart: products}, { params: request.params, headers: request.headers });
  }

  public validateCart(items: CartValidatePayload, req?: Request): Observable<CartValidateResponse> {
    const url = `${environment.server}/checkout/validate`;
    const request = this.getRequest(req);
    return this.http
      .post<CartValidateResponse>(url, items, { params: request.params, headers: request.headers });
  }
}
