import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CartValidatePayload, CartValidateResponse, ProductCart, ProductCartResponse } from '../../../models/cart.model';
import { CartHttpService } from '../cart-http/cart-http.service';
import { lastValueFrom } from 'rxjs';
import { Request } from 'src/app/core/models/request.interface';

@Injectable({
  providedIn: 'root'
})
export class CartBehaviorService {

  private cartHttpService = inject(CartHttpService);
  private readonly productsCart$ = new BehaviorSubject<Array<ProductCart>>([]);
  private readonly cartValidate$ = new BehaviorSubject<CartValidateResponse>({ results: [], errors: [] });

  constructor() { }

  get productsCart(): Array<ProductCart> {
    return this.productsCart$.getValue();
  }

  set productsCart(productsCart: Array<ProductCart>) {
    this.productsCart$.next(productsCart);
  }

  set cartValidate(cartValidate: CartValidateResponse) {
    this.cartValidate$.next(cartValidate);
  }

  get cartValidate(): CartValidateResponse {
    return this.cartValidate$.getValue();
  }

  public async addProductCart(newProduct: ProductCart): Promise<void> {
    let isNewProduct = true;
    const products: Array<ProductCart> = this.productsCart.map((product) => {
      let amount = product.quantity;

      if (product.id === newProduct.id) {
        amount += newProduct.quantity;
        isNewProduct = false;
      }

      if (product.has_stock) {
        if (product.stock && amount > product.stock) {
          amount = product.stock;
        }
      } else {
        amount = 1;
      }

      return {
        ...product,
        quantity: amount,
        total_price: (Number(product.price) + amount).toString(),
      };
    });

    if (isNewProduct) {
      products.push(newProduct);
    }

    this.productsCart$.next([
      ...products
    ]);

    await this.postCart();
  }

  public async getCart(req?: Request): Promise<Array<ProductCartResponse>> {
    const response$ = this.cartHttpService.getCart(req);
    const response = await lastValueFrom(response$);

    this.productsCart = response;

    return response;
  }

  public async postCart(): Promise<any> {
    const products = this.productsCart.map(product => {
      return {
        product_id: product.id,
        quantity: product.quantity,
      }
    });

    const response$ = this.cartHttpService.postCart(products);
    return await lastValueFrom(response$);
  }

  public async validateCart(items: CartValidatePayload, req?: Request): Promise<CartValidateResponse> {
    const response$ = this.cartHttpService.validateCart(items, req);
    return await lastValueFrom(response$);
  }

  public clearLocalCart(): void {
    this.productsCart$.next([]);
  }
}
