import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() {}

  public static encrypt(value: string): string {
    try {
      return btoa(value);
    } catch (e) {
      return value;
    }
  }

  public static decrypt(value: string): string {
    try {
      return atob(value);
    } catch (e) {
      return value;
    }
  }
}
