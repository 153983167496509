import { Injectable, inject, } from '@angular/core';
import { LoginPayload, LoginSocialPayload, SignupPayload } from '../../models/auth-payload.model';
import { AuthHttpService } from '../auth-http/auth-http.service';
import { lastValueFrom, BehaviorSubject, Observable } from 'rxjs';
import { AuthForm } from '../../enum/auth-form.enum';
import { Authentication } from '../../models/auth.interface';
import { WebStorage } from 'src/app/core/enum/storage.enum';
import { StorageService } from 'src/app/core/services/storage/storage.service';
import { ProfileBehaviorService } from 'src/app/core/services/profile/profile-behavior.service';
import { CartBehaviorService } from 'src/app/shared/shared/services/cart/cart-behavior/cart-behavior.service';
import { User, UserAuth } from 'src/app/core/models/user.interface';
import { FavoritesBehaviorService } from 'src/app/shared/shared/services/favorites/favorites-behavior/favorites-behavior.service';
import { UserRole } from 'src/app/core/constants/user-roles.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthBehaviorService {

  private readonly key = 'authentication';
  private authHttpService = inject(AuthHttpService)
  private profileBehaviorService = inject(ProfileBehaviorService);
  private favoritesBehaviorService = inject(FavoritesBehaviorService);
  private cartBehaviorService = inject(CartBehaviorService);

  private readonly authToken$ = new BehaviorSubject<Authentication>({ access_token: '', toke_type: '' });

  constructor(
  ) { }

  get activeSession(): boolean {
    return !(!this.authToken || !this.authToken.access_token);
  }

  get authToken(): Authentication {
    return this.authToken$.getValue();
  }

  set authToken(value: Authentication) {
    this.authToken$.next(value);
  }

  public authTokenObservable$(): Observable<Authentication> {
    return this.authToken$.asObservable();
  }

  private readonly formVisible$: BehaviorSubject<string> = new BehaviorSubject('login')

  get formVisible(): AuthForm {
    return this.formVisible$.getValue() as AuthForm;
  }

  set formVisible(form: AuthForm) {
    this.formVisible$.next(form);
  }

  public async login(data: LoginPayload) {
    try {
      const response$ = this.authHttpService.login(data);
      const response = await lastValueFrom(response$);

      this.storeAuth({ access_token: response, toke_type: 'Bearer' });
      await this.getAndSetUser();
    } catch (error) {
      throw error;
    }
  }

  public async loginSocial(data: LoginSocialPayload) {
    const response$ = this.authHttpService.loginSocial(data);
    const response = await lastValueFrom(response$);

    this.storeAuth({ access_token: response, toke_type: 'Bearer' });
    await this.getAndSetUser();
  }

  public async signupSocial(data: LoginSocialPayload) {
    const response$ = this.authHttpService.signupSocial(data);
    const response = await lastValueFrom(response$);

    this.storeAuth({ access_token: response, toke_type: 'Bearer' });
    await this.getAndSetUser();
  }

  public signup(data: SignupPayload) {
    const response$ = this.authHttpService.signup(data);
    return lastValueFrom(response$);
  }

  public logout(): void {
    this.profileBehaviorService.clearUser();
    this.cartBehaviorService.clearLocalCart()
    this.clearAuthToken();
  }

  public async retrieveAuthFromStorage(): Promise<void> {
    const authFromStorage = StorageService.get(this.key, WebStorage.LocalStorage);
    this.authToken = authFromStorage ? authFromStorage : this.authToken;

    if (this.authToken && this.authToken.access_token.length > 0) {
      await this.getAndSetUser();
    }
  }

  private storeAuth(authToken: Authentication, rememberMe?: boolean): void {
    this.authToken = authToken;
    const storage = WebStorage.LocalStorage;
    StorageService.set(this.key, authToken, storage);
  }

  private async getAndSetUser(): Promise<void> {
    const user = await this.profileBehaviorService.fetchUser();
    if (user) {
      this.cartBehaviorService.productsCart = user.shopping_cart.shopping_cart;
      this.favoritesBehaviorService.productsFavorite = user.user_favorites;
    }
  }

  private clearAuthToken(): void {
    StorageService.clear(this.key, WebStorage.LocalStorage);
    this.authToken = {
      access_token: '',
      toke_type: '',
    };
    this.profileBehaviorService.clearUser();
  }
}
