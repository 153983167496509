import { ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { AuthBehaviorService } from './features/auth/shared/services/auth-behavior/auth-behavior.service';
import { LoadingBehaviorService } from './shared/shared/services/loading/loading-behavior.service';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, SwiperOptions, Swiper } from 'swiper';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'vende-tu-camara';
  private matPaginatorIntl = inject(MatPaginatorIntl);
  private authBehaviorService = inject(AuthBehaviorService);
  private loadingBehaviorService = inject(LoadingBehaviorService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  private destroy$: Subject<boolean> = new Subject();
  public loading = false;

  constructor(
  ) { }

  ngOnInit(): void {
    SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

    this.matPaginatorIntl.itemsPerPageLabel = 'Registros por pagina';
    this.matPaginatorIntl.nextPageLabel = 'Siguiente pagina';
    this.matPaginatorIntl.previousPageLabel = 'Pagina anterior';
    this.matPaginatorIntl.firstPageLabel = 'Primera pagina';
    this.matPaginatorIntl.lastPageLabel = 'Ultima pagina';

    this.retrieveAuthFromStorage();
    this.subscribeLoading();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private retrieveAuthFromStorage(): void {
    this.authBehaviorService.retrieveAuthFromStorage();
  }

  private subscribeLoading(): void {
    this.loadingBehaviorService.getLoading$().pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      setTimeout(() => {
        this.loading = value;
      }, 0);
    });
  }
}
