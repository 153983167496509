import { Injectable, inject } from '@angular/core';
import { FavoritesHttpService } from '../favorites-http/favorites-http.service';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ProductFavorite } from '../../../models/favorite.model';
import { Request } from 'src/app/core/models/request.interface';

@Injectable({
  providedIn: 'root'
})
export class FavoritesBehaviorService {

  private favoritesHttpService = inject(FavoritesHttpService);
  private readonly productsFavorite$ = new BehaviorSubject<Array<ProductFavorite>>([]);

  constructor() { }

  get productsFavorite(): Array<ProductFavorite> {
    return this.productsFavorite$.getValue();
  }

  set productsFavorite(productsFavorite: Array<ProductFavorite>) {
    this.productsFavorite$.next(productsFavorite);
  }

  public async toggleProductFavorite(product: ProductFavorite): Promise<void> {
    let products = this.productsFavorite;
    const issetProducts = this.productsFavorite.find(({ id }) => id === product.id);

    if (issetProducts) {
      products = products.filter(({ id }) => id !== product.id);
    } else {
      products.push(product);
    }

    this.productsFavorite$.next(products);

    try {
      await this.postFavorites();
    } catch (error) {
    }
  }

  public async getFavorites(req?: Request): Promise<Array<ProductFavorite>> {
    const response$ = this.favoritesHttpService.getFavorites(req);
    const response = await lastValueFrom(response$);

    this.productsFavorite = response;

    return response;
  }

  public async postFavorites(): Promise<any> {
    const productIds = this.productsFavorite.map(({ id }) => id);
    try {
      const response$ = this.favoritesHttpService.postFavorite(productIds);
      return await lastValueFrom(response$);
    } catch (error) {

    }
  }

  public clearLocalCart(): void {
    this.productsFavorite$.next([]);
  }
}
