import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { CryptoService } from '../crypto/crypto.service';
import { WebStorage } from '../../enum/storage.enum';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {}

  /**
   * Get the anything from local storage
   */
  public static get(key: string, from?: WebStorage): any {
    const { enableStorageEncryption, defaultStorage } = environment;

    from = from ? from : defaultStorage;
    const storage = this.getWebStorage(from);
    let value: any;
    const encryptedKey = enableStorageEncryption
      ? CryptoService.encrypt(key)
      : key;
    const valueEncrypted = storage.getItem(encryptedKey);
    if (valueEncrypted === null) {
      return valueEncrypted;
    }
    const valueDecrypted = enableStorageEncryption
      ? CryptoService.decrypt(valueEncrypted)
      : valueEncrypted;
    try {
      value = JSON.parse(valueDecrypted);
    } catch (e) {
      value = valueDecrypted;
    }
    return value;
  }

  /**
   * Storage the anything in local storage
   */
  // tslint:disable-next-line:typedef
  public static set(key: string, value: any, to?: WebStorage) {
    const { enableStorageEncryption, defaultStorage } = environment;

    to = to ? to : defaultStorage;
    const storage = this.getWebStorage(to);

    const valueToString = JSON.stringify(value);
    const encryptedValue = enableStorageEncryption
      ? CryptoService.encrypt(valueToString)
      : valueToString;
    const encryptedKey = enableStorageEncryption
      ? CryptoService.encrypt(key)
      : key;
    storage.setItem(encryptedKey, encryptedValue);
  }

  private static getWebStorage = (storage: WebStorage): Storage => {
    switch (storage) {
      case WebStorage.LocalStorage:
        return localStorage;
      case WebStorage.SessionStorage:
        return sessionStorage;
    }
  };

  public static clear = (key?: string, webStorage?: WebStorage) => {
    const { enableStorageEncryption, defaultStorage } = environment;
    webStorage = webStorage ? webStorage : defaultStorage;
    const storage = StorageService.getWebStorage(webStorage);

    if (key) {
      const encryptedKey = enableStorageEncryption
        ? CryptoService.encrypt(key)
        : key;
      storage.removeItem(encryptedKey);
    } else {
      storage.clear();
    }
  };
}
