import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxFileDropModule } from 'ngx-file-drop';
import { InputFileComponent } from './components/input-file/input-file.component';
import { MaterialModule } from '../material/material.module';
import { ImagesListComponent } from './components/images-list/images-list.component';

@NgModule({
  declarations: [
    InputFileComponent,
    ImagesListComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgxFileDropModule,
  ],
  exports: [
    InputFileComponent,
    ImagesListComponent,
  ]
})
export class FileModule { }
