import { Injectable } from '@angular/core';
import { Request } from '../../models/request.interface';

@Injectable({
  providedIn: 'root'
})
export class HttpGeneralService {

  constructor() { }

  protected getRequest(request?: Request): Request {
    return {
      params: request?.params ?? {},
      headers: request?.headers ?? {},
    };
  };
}
