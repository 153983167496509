import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { StorageService } from '../storage/storage.service';
import { ProfileHttpService } from './profile-http.service';
import { SimpleResponse, User, UserAddress, UserAuth, UserIdentificationTypes } from '../../models/user.interface';
import { UserRole } from '../../constants/user-roles.constant';
import { Address } from '../../models/address.interface';
import { Request } from '../../models/request.interface';
import { Order } from 'src/app/shared/shared/models/order.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileBehaviorService {

  private readonly key = 'user-profile';
  private profileHttpService = inject(ProfileHttpService);
  private readonly userProfile$ = new BehaviorSubject<User | null>(null);
  private readonly address$ = new BehaviorSubject<UserAddress | null>(null);

  constructor() {
    this.userProfile = StorageService.get(this.key);
  }

  set userProfile(value: User | null) {
    this.userProfile$.next(value);
    StorageService.set(this.key, this.userProfile);
  }

  get userProfile(): User {
    return this.userProfile$.getValue() as User;
  }

  get role(): UserRole {
    return (this.userProfile && this.userProfile.roles && this.userProfile.roles.length > 0)? this.userProfile.roles[0] : UserRole.user
  }

  set address(value: UserAddress | null) {
    this.address$.next(value);
  }

  get address(): UserAddress {
    return this.address$.getValue() as UserAddress;
  }

  public userProfileObservable$(): Observable<User | null> {
    return this.userProfile$.asObservable();
  }

  async fetchUser(): Promise<UserAuth | null> {
    try {
      const $response = this.profileHttpService.getProfile();
      const user = await lastValueFrom($response);


      this.userProfile = {
        ...user,
        full_name: `${user.first_name} ${user.last_name}`
      };

      if (user.user_addresses) {
        this.address = user.user_addresses.find(({ isDefault }) => isDefault) as UserAddress;
      }

      return user;
    } catch (error) {
      return null;
    }
  }

  async getUserIdentifications(): Promise<Array<UserIdentificationTypes>> {
    const $response = this.profileHttpService.getUserIdentifications();
    return await lastValueFrom($response);
  }

  async updateUser(user: User): Promise<void> {
    try {
      const response = await lastValueFrom(this.profileHttpService
        .putUser(user));
    } catch (error) {
      throw error;
    }
  }

  async updatePassword(password: string): Promise<void> {
    try {
      const response = await this.profileHttpService
        .putPassword(password)
        .toPromise();
    } catch (error) {
      throw error;
    }
  }

  async getShoppingHistory(req?: Request): Promise<Array<Order>> {
    return await lastValueFrom(this.profileHttpService
      .getShoppingHistory(req));
  }

  public clearUser(): void {
    this.userProfile$.next(null);
    StorageService.clear(this.key);
  }

  async sendPassRecoverLink(req?: Request): Promise<SimpleResponse> {
    return await lastValueFrom(this.profileHttpService.sendPassRecoverLink(req));
  }

  async restorePass(req?: Request): Promise<SimpleResponse> {
    return await lastValueFrom(this.profileHttpService.restorePass(req));
  }

  async activateAccount(req?: Request): Promise<SimpleResponse> {
    return await lastValueFrom(this.profileHttpService.activateAccount(req));
  }
}
