import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingBehaviorService {

  private readonly loading$ = new BehaviorSubject<boolean>(false);

  constructor() { }

  get loading(): boolean {
    return this.loading$.getValue();
  }

  set loading(loading: boolean) {
    this.loading$.next(loading);
  }

  getLoading$(): Observable<boolean> {
    return this.loading$.asObservable();
  }

}
