import { WebStorage } from "src/app/core/enum/storage.enum";

export const environment = {
  production: true,
  server: 'https://vendetucamara-api-prod-38e96c4cc641.herokuapp.com',

  defaultStorage: WebStorage.LocalStorage,
  enableStorageEncryption: true,

  socialLogin: {
    google: {
      clientId: '1080949097634-tif4dgrd7a2i21jv8u9ji5q9q2dk84pg.apps.googleusercontent.com',
    },
    facebook: {
      clientId: '898978105221330',
    },
  }
};
