import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { HttpGeneralService } from 'src/app/core/services/http-general/http-general.service';
import { ProductFavorite } from '../../../models/favorite.model';
import { environment } from 'src/environments/environment';
import { Request } from 'src/app/core/models/request.interface';

@Injectable({
  providedIn: 'root'
})
export class FavoritesHttpService extends HttpGeneralService {

  private http = inject(HttpClient);

  constructor() {
    super();
  }

  public getFavorites(req?: Request): Observable<Array<ProductFavorite>> {
    const url = `${environment.server}/user/favorites`;
    const request = this.getRequest(req);
    return this.http
      .get<{ user_favorites: Array<ProductFavorite>}>(url, { params: request.params, headers: request.headers })
      .pipe(
        map((response) => response.user_favorites)
      );
  }

  public postFavorite(productIds: Array<number>, req?: Request): Observable<any> {
    const url = `${environment.server}/user/favorites`;
    const request = this.getRequest(req);
    return this.http
      .post<any>(url, {favorite_ids: productIds}, { params: request.params, headers: request.headers });
  }
}
