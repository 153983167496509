import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoginPayload, LoginSocialPayload, SignupPayload } from '../../models/auth-payload.model';
import { Observable, map } from 'rxjs';
import { SignupResponse } from '../../models/auth.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthHttpService {

  constructor(
    private http: HttpClient
  ) { }

  public login(data: LoginPayload): Observable<string> {
    const url = `${environment.server}/login/email`;
    return this.http
      .post<{ value: string }>(url, data)
      .pipe(map((response) => response.value));
  }

  public loginSocial(data: LoginSocialPayload) {
    const url = `${environment.server}/login/sso`;
    let headers = {
      'x-social-type': data.type
    };

    return this.http
      .post<{ value: string }>(url, { value: data.token }, { headers })
      .pipe(map((response) => response.value));
  }

  public signupSocial(data: LoginSocialPayload) {
    const url = `${environment.server}/signup/sso`;
    let headers = {
      'x-social-type': data.type
    };

    return this.http
      .post<{ value: string }>(url, { value: data.token }, { headers })
      .pipe(map((response) => response.value));
  }

  public signup(data: SignupPayload): Observable<SignupResponse> {
    const url = `${environment.server}/signup/email`;
    return this.http.post<SignupResponse>(url, data);
  }
}
