import { Injectable, inject } from '@angular/core';

import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';

import { EMPTY, Observable, catchError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage/storage.service';
import { AuthBehaviorService } from 'src/app/features/auth/shared/services/auth-behavior/auth-behavior.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private readonly baseUrl: string;
  private authBehaviorService = inject(AuthBehaviorService);

  constructor(
  ) {
    this.baseUrl = environment.server;
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let cloneReq = this._setAccept(req);
    cloneReq = this._setToken(cloneReq);
    return next.handle(cloneReq).pipe(
      catchError((error: HttpErrorResponse) => {
        return new Promise<any>((resolve, reject) => {
          reject(this._errorBehavior(error));
        });
      })
    );
  }

  private _errorBehavior(error: HttpErrorResponse) {
    switch (error.status) {
      case 401:
        this.authBehaviorService.logout();
        return EMPTY;

      default:
        return error;
    }
  }

  private _setAccept(req: HttpRequest<any>) {
    let cloneReq = req.clone();
    const { url } = cloneReq;
    if (url.includes(this.baseUrl)) {
      cloneReq = cloneReq.clone({ headers: req.headers.set('Accept', 'application/json') });
      cloneReq = cloneReq.clone({ headers: req.headers.set('Access-Control-Allow-Origin', '*') });
      cloneReq = cloneReq.clone({ headers: req.headers.set('Access-Control-Allow-Methods', 'GET, POST, PUT, OPTIONS, PATH, DELETE') });
    }

    return cloneReq;
  }

  private _setToken(req: HttpRequest<any>): HttpRequest<any> {
    let cloneReq = req.clone();
    const { url } = cloneReq;
    const { access_token = null, toke_type } = this.authBehaviorService.authToken || {};

    if (
      access_token &&
      toke_type &&
      url.includes(this.baseUrl)
    ) {
      cloneReq = req.clone({
        headers: req.headers.set('Authorization', `${toke_type} ${access_token}`),
      });
    }
    return cloneReq;
  }

}
